
@import '~@/assets/scss/functions';
@import '~@/assets/scss/silent-classes';
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

.arg-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.arg {
  margin: 0;
  padding: 12px 0;
  & ~ .arg {
    border-top: 1px solid $grey-1000;
  }
  .dark-mode & {
    border-top-color: $grey-200;
  }
}
.arg__name {
  @extend %code-font-family;
  line-height: $baseline-px;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: -0.3px;
  span {
    font-size: $small-font-rem;
    color: $grey-800;
    font-weight: 400;
  }
}
.arg__desc {
  padding: 0 4px;
}

.option-list {
  display: inline-block;
  padding: 0;
  margin: 6px 8px 0 8px;
  border: 1px solid $grey-1000;
  border-radius: 8px;
  list-style: none;
  .dark-mode & {
    border-color: $grey-200;
  }
}
.option {
  position: relative;
  margin: 0;
  padding: 6px 12px 6px 20px;
  &:before {
    content: '';
    position: absolute;
    left: 8px;
    top: 14px;
    border: 2px solid currentColor;
    border-radius: 50%;
  }
  & ~ .option {
    border-top: 1px solid $grey-1000;
  }
  .dark-mode & {
    border-top-color: $grey-200;
  }
}
