
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

.question {
  margin: $baseline-rem 0 0 0;
  padding: $baseline-rem ($gutter-px * .5) $baseline-rem ($gutter-px * .5);
  border-radius: 6px;
  background: $white;
  box-shadow: $card-shadow;
  h2 {
    margin: 0;
  }
  .dark-mode & {
    background: $grey-350;
    box-shadow: $card-shadow--dark;
  }
}
