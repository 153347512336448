
@import '~@/assets/scss/functions';
@import '~@/assets/scss/silent-classes';
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

.command {
  position: relative;
  padding: ($baseline-px * .25) 8px ($baseline-px * .5) 8px;
  border-radius: 6px;
  background: $white;
  box-shadow: $card-shadow;
  & ~ .command {
    margin-top: $baseline-rem;
  }
  p {
    margin: 0;
  }
  .tooltip {
    right: (-8px);
    left: (-8px);
  }
  .dark-mode & {
    background: $grey-350;
    box-shadow: $card-shadow--dark;
  }
}
.command__name {
  @extend %code-font-family;
  margin: 0;
  color: $grey-200;
  font-size: $h4-font-rem;
  line-height: ($baseline-px * 2);
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: -0.8px;
  .command__button {
    margin: 4px 0;
    line-height: ($baseline-px * 2) - 12px;
  }
  span:before {
    content: '\002F';// Forward slash
    color: $grey-800;
  }
  .dark-mode & {
    color: $white;
  }
}
.command__desc {
  margin: 2px 0 10px 0;
  padding: 0 4px;
  line-height: $baseline-px;
  color: $grey-250;
  .dark-mode & {
    color: $grey-1200;
  }
}
.command__button {
  display: inline-block;
  border: 1px solid $grey-1000;
  background: $white;
  box-shadow: $button-shadow;
  vertical-align: top;
  &:hover {
    background: $grey-1150;
  }
  &:active {
    background: $grey-1100;
  }
  &.active {
    color: $grey-1200;
    background: $grey-250;
    &:active {
      background: $grey-100;
    }
  }
  .dark-mode & {
    border-color: $grey-200;
    background: $grey-350;
    box-shadow: $button-shadow--dark;
    &:hover {
      background: $grey-300;
    }
    &:active {
      background: $grey-250;
    }
    &.active {
      color: $grey-200;
      background: $grey-1200;
      &:active {
        background: $grey-1100;
      }
    }
  }
}
.command__button--subcommands {
  border-radius: 20px;
  padding: 1px 14px;
  &:active {
    padding: 2px 14px 0 14px;
  }
  &:after {
    content: '';
    display: inline-block;
    width: ($baseline-px * 2) - 12px;
    height: ($baseline-px * 2) - 12px;
    background: url-encode("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' fill='#{$grey-800}'><circle cx='200' cy='300' r='30'/><circle cx='325' cy='300' r='30'/><circle cx='450' cy='300' r='30'/></svg>") 50% 50%/100% 100%;
    vertical-align: top;
  }
}
.command__button--args {
  border-radius: 8px;
  padding: 1px 6px;
  &:active {
    padding: 2px 6px 0 6px;
  }
}

.subcommand-list {
  margin: 12px 0 (-12px) 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.subcommand {
  position: relative;
  margin: 12px 0 0 0;
  padding: 12px 0;
  border-top: 1px solid $grey-1000;
  & ~ .subcommand {
    margin: 0;
  }
  .tooltip {
    right: (-16px);
    left: (-16px);
  }
  .dark-mode & {
    border-top-color: $grey-200;
  }
}
.subcommand__name {
  @extend %code-font-family;
  line-height: $baseline-px;
  font-weight: 600;
  letter-spacing: -0.3px;
  button {
    line-height: $baseline-px - 4px;
  }
  span {
    color: $grey-800;
    &:before {
      content: '\002F';// Forward slash
    }
  }
}
.subcommand__desc {
  padding: 0 4px;
}

.permissions {
  display: inline-block;
  margin: 4px 0 0 0;
  padding: 4px 8px 0 8px;
  border-top: 1px solid $grey-1100;
  .dark-mode & {
    border-color: $grey-250;
  }
  code ~ code {
    margin-left: 6px;
  }
}
