
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

.patreon {
  padding-top: $baseline-rem;
  .flexbox__item {
    margin-top: $baseline-rem;
  }
}
