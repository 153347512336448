
@import '~@/assets/scss/functions';
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

.commands-column {
  width: 100%;
  @include media-query('gt-800') {
    width: 50%;
  }
}
.commands-list {
  padding: 0;
  list-style: none;
  text-align: center;
}
