@use 'sass:color';
/*---------------------------------------------------------------------------*\
    Main Navigation
\*---------------------------------------------------------------------------*/
// '.main-nav-button' in '_top-bar.scss' partial
.main-nav__container {
  position: fixed;
  top: $nav-height;
  left: calc(100% + $nav-mobile-width);
  width: $nav-mobile-width;
  height: 100%;
  background: $white;
  text-align: left;
  box-shadow: none;
  transition: left .3s ease-in, box-shadow 0s linear .3s;
  z-index: 2;
  &.active {
    left: calc(100% - $nav-mobile-width);
    box-shadow: $navigation-drawer-shadow;
    transition: left .3s ease-out;
  }
  @include media-query('gt-800') {
    position: absolute;
    top: 0;
    right: 64px + $gutter-px;
    left: auto;
    width: auto;
    height: $nav-height;
    float: right;
    z-index: 3;
    transition: none;
    &.active {
      left: auto;
      box-shadow: none;
    }
  }
  .dark-mode & {
    background: $grey-350;
    &.active {
      box-shadow: $navigation-drawer-shadow--dark;
      @include media-query('gt-800') {
        box-shadow: none;
      }
    }
  }
}
.main-nav {
  display: flex;
  margin: 0;
  flex-flow: column nowrap;
  justify-content: flex-start;
  &:hover .main-nav__link-name {
    opacity: .75;
  }
  @include media-query('gt-800') {
    flex-flow: row nowrap;
  }
}
.main-nav__link {
  display: inline-block;
  padding: 0 ($baseline-px * .5);
  width: 100%;
  height: $nav-logo-height;
  color: $grey-600;
  text-decoration: none;
  vertical-align: top;
  transition: opacity .15s;
  &:hover {
    color: $red-700;
    .main-nav__link-name {
      opacity: 1;
      &:after {
        width: 100%;
      }
    }
  }
  &.active {
    color: $grey-200;
    .main-nav__link-name:after {
      width: 100%;
    }
  }
  @include media-query('gt-800') {
    width: auto;
    height: $nav-height;
    &:hover .main-nav__link-name:after,
    &.active .main-nav__link-name:after {
      height: 4px;
    }
  }
  .dark-mode & {
    color: $grey-1000;
    &:hover {
      color: color.scale($red-400, $saturation: 100%, $lightness: 42%);
    }
    &.active {
      color: $white;
    }
  }
}
.main-nav__link-name {
  position: relative;
  display: inline-block;
  max-width: $nav-mobile-width - $baseline-px;
  font-size: $nav-font-rem;
  line-height: $nav-logo-height;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: top;
  pointer-events: none;
  overflow: hidden;
  &:after {
    @extend %psuedo-element;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background: currentColor;
    transition: width .15s;
  }
  @include media-query('gt-800') {
    line-height: $nav-height;
    &:after {
      bottom: 1px;
      width: 100%;
      height: 0;
      transition: height .15s;
    }
  }
}
.q-kern {
  letter-spacing: -2px;
}
.mobile-navigation-cover {
  position: fixed;
  top: $nav-height;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-in, visibility 0s linear .3s;
  &.active {
    opacity: .5;
    visibility: visible;
    transition: opacity .3s ease-out;
  }
  @include media-query('gt-800') {
    display: none;
  }
}