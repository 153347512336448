
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

h2.anchor {
  margin-top: -$nav-height;
  padding-top: $nav-height + $h2-margin-px;
}
p, li {
  position: relative;
  z-index: 1;
}
