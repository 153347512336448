/*---------------------------------------------------------------------------*\
    Top Bar
\*---------------------------------------------------------------------------*/
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  text-align: left;
  z-index: 2;
}
.top-bar__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  box-shadow: $top-bar-shadow;
  z-index: 3;
  .dark-mode & {
    background: $grey-350;
    box-shadow: $top-bar-shadow--dark;
  }
}

.top-bar__logo {
  display: inline-block;
  margin: $nav-logo-padding 0;
  height: $nav-logo-height;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: top;
  picture {
    display: inline-block;
    width: 41.5px;
    height: $nav-logo-height;
    vertical-align: top;
  }
}
.top-bar__logo-wordmark {
  @extend %header-font-family;
  position: relative;
  display: inline-block;
  margin-left: ($baseline-px * .25);
  font-size: $h3-font-px;
  line-height: $nav-logo-height;
  font-weight: 600;
  color: $red-500;
  @include text-stroke($black,.5);
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    &:after {
      content: 'GW2Bot';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient($red-700 32.5%,$red-500 51.6%,$red-100 70.8%);
      background-clip: text;
      color: transparent;
      text-shadow: none;
      .dark-mode & {
        background: linear-gradient($red-700 32.5%,$red-600 51.6%,$red-500 70.8%);
        background-clip: text;
      }
    }
  }
}
.top-bar__toggles {
  position: absolute;
  top: $nav-logo-padding;
  right: ($gutter-px * .5);
  display: flex;
  flex-flow: row nowrap;
  margin-left: (-($gutter-px * .5));
  @include media-query('gt-600') {
    right: $gutter-px;
  }
}
.top-bar__toggle {
  display: inline-block;
  padding-left: ($gutter-px * .5);
}
.top-bar__toggle--navigation {
  @include media-query('gt-800') {
    display: none;
  }
}

.dark-mode-button {
  position: relative;
  margin: 22px 6px;
  padding: 0;
  width: 44px;
  height: 20px;
  border: 0;
  border-radius: 10px;
  background: $grey-800 url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 880 400"><path fill="#fff" d="M285,280c-60.75,0-110-49.25-110-110,0-49.17,32.26-90.8,76.77-104.89-8.66-1.71-17.61-2.61-26.77-2.61-75.94,0-137.5,61.56-137.5,137.5s61.56,137.5,137.5,137.5c54.64,0,101.83-31.87,124.01-78.04-18.03,12.93-40.13,20.54-64.01,20.54Z"/><path fill="#ffff80" d="M712.5,200c0,38.66-31.34,70-70,70s-70-31.34-70-70,31.34-70,70-70,70,31.34,70,70Zm-70-162.5l-25,75h50l-25-75Zm0,325l25-75h-50l25,75Zm-162.5-162.5l75,25v-50l-75,25Zm325,0l-75-25v50l75-25Zm-277.4-114.9l35.36,70.71,35.36-35.36-70.71-35.36Zm229.81,229.81l-35.36-70.71-35.36,35.36,70.71,35.36Zm-229.81,0l70.71-35.36-35.36-35.36-35.36,70.71Zm229.81-229.81l-70.71,35.36,35.36,35.36,35.36-70.71Z"/></svg>') 50% 50%/100% 100%;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 9px;
    margin: (-13px) 0 0 (-13px);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: $white;
    box-shadow: $button-shadow;
    transition: left .15s;
  }
  &.active:after {
    left: 35px;
  }
  .dark-mode & {
    background-color: $grey-700;
    &:after {
      background: $grey-1200;
    }
  }
}
.main-nav-button {
  position: relative;
  padding: 0;
  width: $nav-logo-height;
  height: $nav-logo-height;
  border: 0;
  border-radius: 50%;
  background: transparent;
  color: $grey-400;
  &:active {
    background: $grey-1200;
  }
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: (-2px) 0 0 (-14px);
    border-radius: 2px;
    width: 28px;
    height: 4px;
    background: currentColor;
    transition: transform .15s ease-in;
    &:before, &:after {
      @extend %psuedo-element;
      position: absolute;
      left: 0;
      border-radius: 2px;
      width: 28px;
      height: 4px;
      background: currentColor;
      transition: transform .15s ease-in, top .15s ease-out .15s, bottom .15s ease-out .15s;
    }
    &:before {
      bottom: 8px;
    }
    &:after {
      top: 8px;
    }
  }
  &.active div {
    transform: rotate(45deg);
    transition: transform .15s ease-out .15s;
    &:before, &:after {
      transition: top .15s ease-in, bottom .15s ease-in, transform .15s ease-out .15s;
    }
    &:before {
      bottom: 0;
    }
    &:after {
      top: 0;
      transform: rotate(-90deg);
    }
  }
  .dark-mode & {
    color: $grey-1200;
    &:active {
      background: $grey-300;
    }
  }
}
