
@import '~@/assets/scss/functions';
@import '~@/assets/scss/silent-classes';
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

.feature-media {
  position: relative;
  height: 100vh;
  height: calc(100vh - $nav-height);
  min-height: 480px;
  overflow: hidden;
}
// https://matkl.github.io/average-color/
.january {background: #5A4F3E}
.february {background: #5093D7}
.march {background: #9B9795}
.april {background: #464B30}
.may {background: #4C3A16}
.june {background: #685B50}
.july {background: #7F6857}
.august {background: #928167}
.september {background: #AB694F}
.october {background: #513927}
.november {background: #65493C}
.december {background: #878175}

.feature-media__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.feature-media__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  &:before {
    @extend %psuedo-element;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black,.33);
  }
}
.feature-title {
  margin: 0;
  color: $white;
  text-shadow: 0 4px  16px $black,
               0 8px  32px rgba($black,.5),
               0 16px 64px rgba($black,.25),
               0 32px 128px rgba($black,.125);
  @include media-query('gt-600') {
    font-size: $xl-font-rem;
    line-height: $xl-line-rem;
  }
}
.feature-button {
  margin-top: $baseline-rem * 2;
  box-shadow: 0 4px  16px $black,
              0 8px  32px rgba($black,.5),
              0 16px 64px rgba($black,.25),
              0 32px 128px rgba($black,.125);
}

.party-quaggan {
  display: inline-block;
  width: 100%;
  max-width: 320px;
  max-height: 394px;
  vertical-align: top;
}

.bot-demo {
  margin: $baseline-rem 0;
  video {
    display: inline-block;
    border-radius: 8px;
    width: 100%;
    max-width: 687px;
    max-height: 543px;
    vertical-align: top;
    box-shadow: $card-shadow;
    .dark-mode & {
      box-shadow: $card-shadow--dark;
    }
  }
}
