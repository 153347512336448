
@import '~@/assets/scss/colors';
@import '~@/assets/scss/settings';

footer {
  position: relative;
  margin: ($baseline-rem * 2) 0 0 0;
  padding: $baseline-rem 0 ($baseline-rem * 2) 0;
  text-align: center;
  width: 100%;
  background: $grey-1100;
  .dark-mode & {
    background: $grey-250;
  }
}
